import randomNumber from "random-number";

export const madewithlove = "#F20928";
export const colors = [
    "#e91e63",
    "#9C27B0",
    "#00BCD4",
    "#2196F3",
    "#8bc34a",
    "#ff5722",
];

export const randomColor = () =>
    colors[
        randomNumber({
            min: 0,
            max: colors.length - 1,
            integer: true,
        })
    ];

export default colors;
