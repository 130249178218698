import { graphql } from "gatsby";
import React, { useState } from "react";
import { Query } from "..";
import Keyword from "../components/Elements/Keyword";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Layout/SEO";
import { madewithlove } from "../data/colors";
import {
    ABOUT,
    BLOG,
    ILLUSTRATION,
    MUSIC,
    OPEN_SOURCE,
    PHOTOSETS,
    PUBLIC_SPEAKING,
    STORIES,
    TODAY_IS_SUNDAY,
    WEBCOMIC,
} from "../routes";
import GatsbyImage, { FluidObject } from "gatsby-image";
import { isMobile } from "react-device-detect";

const IndexPage = ({ data }: { data: Query }) => {
    const [hoveredPage, setHoveredPage] = useState(ABOUT);

    const getProps = (route) => ({
        to: route,
        onMouseEnter: () => (isMobile ? null : setHoveredPage(route)),
        onMouseLeave: () => (isMobile ? null : setHoveredPage(null)),
    });

    const currentImage = data.allFile.nodes.find(
        (node) => `/${node.name}` === (hoveredPage || ABOUT),
    );

    return (
        <Layout className={"dark:bg-gray-800 dark:text-white"}>
            <SEO title="Home" />
            <div className="flex w-full flex-col-reverse lg:flex-row">
                <section className="flex w-full lg:w-7/12 justify-center items-center">
                    <div className="p-5 md:p-10 lg:p-20 xl:p-32 text-center xl:text-3xl lg:text-2xl text-xl">
                        <p className={"my-5 lg:my-10"}>
                            Hello there! My name is{" "}
                            <Keyword {...getProps(ABOUT)}>Emma Fabre</Keyword>.
                        </p>
                        <p className={"my-5 lg:my-10"}>
                            I help build the{" "}
                            <Keyword {...getProps(OPEN_SOURCE)}>web</Keyword>{" "}
                            for{" "}
                            <Keyword
                                {...getProps("/madewithlove")}
                                to="https://madewithlove.be"
                                color={madewithlove}
                            >
                                madewithlove
                            </Keyword>{" "}
                            and I frequently like to{" "}
                            <Keyword {...getProps(PUBLIC_SPEAKING)}>
                                talk
                            </Keyword>{" "}
                            and <Keyword {...getProps(BLOG)}>write</Keyword>{" "}
                            about it. I like to create things, like taking{" "}
                            <Keyword {...getProps(PHOTOSETS)}>
                                photographies
                            </Keyword>
                            , composing{" "}
                            <Keyword {...getProps(MUSIC)}>music</Keyword> or
                            writing{" "}
                            <Keyword {...getProps(STORIES)}>
                                short stories
                            </Keyword>
                            .
                        </p>
                        <p className={"my-5 lg:my-10"}>
                            I also used to do a bit of{" "}
                            <Keyword {...getProps(ILLUSTRATION)}>
                                illustration
                            </Keyword>
                            , digital drawing,{" "}
                            <Keyword {...getProps(TODAY_IS_SUNDAY)}>
                                in-game renders
                            </Keyword>
                            , and also had an{" "}
                            <Keyword {...getProps(WEBCOMIC)}>
                                interactive webcomic
                            </Keyword>{" "}
                            for a while.
                        </p>
                    </div>
                </section>
                <div className="w-full lg:w-5/12 w-0">
                    {currentImage && (
                        <>
                            <GatsbyImage
                                loading={"eager"}
                                imgStyle={{
                                    objectFit: "contain",
                                    objectPosition: "top",
                                }}
                                style={{ maxHeight: 400 }}
                                className="lg:hidden mt-10"
                                fluid={
                                    currentImage.childImageSharp
                                        .fluid as FluidObject
                                }
                            />
                            <GatsbyImage
                                loading={"eager"}
                                className="h-screen hidden lg:block"
                                fluid={
                                    currentImage.childImageSharp
                                        .fluid as FluidObject
                                }
                            />
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    {
        allSitePage(filter: { isCreatedByStatefulCreatePages: { eq: true } }) {
            nodes {
                path
                internalComponentName
                context {
                    route
                    title
                }
            }
        }
        allFile(filter: { relativeDirectory: { eq: "pages" } }) {
            nodes {
                name
                childImageSharp {
                    fluid(quality: 90, maxHeight: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`;

export default IndexPage;
