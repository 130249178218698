import randomNumber from "random-number";
import React from "react";
import { randomColor } from "../../data/colors";
import ExternalLink from "./ExternalLink";
import { Link } from "gatsby";

const Keyword = (props) => {
    const className = "inline-block no-underline text-white";
    const boundaries = { rotate: 2, skew: 10 };

    const rotate = randomNumber({
        min: -boundaries.rotate,
        max: boundaries.rotate,
        integer: true,
    });

    const skew = randomNumber({
        min: -boundaries.skew,
        max: boundaries.skew,
        integer: true,
    });

    const flip = (number) => (number > 0 ? -number : Math.abs(number));
    const transform = (skew, rotate) => `skew(${skew}deg) rotate(${rotate}deg)`;
    const childrenProps = {
        className,
        style: {
            transform: transform(flip(skew), flip(rotate)),
        },
        ...props,
    };

    const children = props.to.includes("http") ? (
        <ExternalLink href={props.to} {...childrenProps}>
            {props.children}
        </ExternalLink>
    ) : (
        <Link {...props} {...childrenProps} />
    );

    return (
        <span
            className="inline-block transition"
            style={{
                backgroundColor: props.color || randomColor(),
                transform: transform(skew, rotate),
            }}
        >
            {children}
        </span>
    );
};

export default Keyword;
